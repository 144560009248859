import { SITE_NAME, SITE_URL, IOS_APP_ID, FACEBOOK_APP_ID } from '../../../../../../config/environment';

import {
    generateTitle as defaultGenerateTitle,
    generateDescription as defaultGenerateDescription,
    generateOpenGraphTitle as defaultGenerateOpenGraphTitle,
    generateOpenGraphDescription as defaultGenerateOpenGraphDescription,
} from '../../../../components/meta-data/utils';
export {
    openGraphImageHeight,
    openGraphImageWidth,
    openGraphSiteName,
    socialMediaData,
    generateAppleSmartBanner,
    generateGoogleSmartBanner,
    generateCanonical,
    generateOpenGraphImage,
    generateOpenGraphUrl
} from '../../../../components/meta-data/utils';
import { getSearchText, getLoactionText } from '../../../../util/search/';

const generateDescriptionText = ({ searchQuery }) => {
    const {
        channel,
        page,
        searchMode,
        filters: {
            location,
            mealTypes,
            keywords
        }
    } = searchQuery;

    const descriptionComponents = [];

    descriptionComponents.push(getSearchText(channel, mealTypes, keywords));
    descriptionComponents.push(channel);
    
    const locationText = getLoactionText(location);

    if (locationText) {
        descriptionComponents.push(locationText);
    }

    const descriptionText = `${descriptionComponents.join(' ')}`;
    return `${descriptionText.charAt(0).toUpperCase()}${descriptionText.slice(1)}. You look hungry. Find meals near you with ${SITE_NAME}.`;
};

export const generateTitleText = ({ searchQuery }) => {
    const {
        channel,
        page,
        searchMode,
        filters: {
            location,
            mealTypes,
            keywords
        }
    } = searchQuery;

    const titleComponents = [];

    titleComponents.push(getSearchText(channel, mealTypes, keywords));
    const locationText = getLoactionText(location);

    if(locationText) {
        titleComponents.push(locationText);
    }

    const titleText = `${titleComponents.join(' ')}`;
    return `${titleText.charAt(0).toUpperCase()}${titleText.slice(1)}`;
};

export const generateTitle = ({ searchQuery }) => defaultGenerateTitle(`${generateTitleText({ searchQuery })} - ${SITE_NAME}`)
export const generateDescription = ({ searchQuery }) => defaultGenerateDescription(generateDescriptionText({ searchQuery }))
export const generateOpenGraphTitle = ({ searchQuery }) => defaultGenerateTitle(generateTitleText({ searchQuery }))
export const generateOpenGraphDescription = ({ searchQuery }) => defaultGenerateOpenGraphDescription(generateDescriptionText({ searchQuery }))