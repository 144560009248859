import { hot } from 'react-hot-loader';
export { default as fetchData } from './fetchData';
import React from 'react';
import MetaData from './components/meta-data';
import Header from './components/header';
import ResultsList from './components/results-list';
import ResultsFooter from './components/results-footer';
import styled from 'styled-components';

const MainContent = styled.div``
const BottomContent = styled.div``

const View = ({ data, location }) => {
  return (
    <>
      <MetaData data={data} />
      <MainContent>
        <Header data={data} />
        <ResultsList data={data} />
      </MainContent>
      <BottomContent>
        <ResultsFooter data={data} />
      </BottomContent>
    </>
  );
};

export default hot(module)(View);
