import { generateTitleText } from '../meta-data/utils';
import useSearch from '../../../../util/search/useSearch';

const adaptData = ({ search }) => {
    const { searchQuery } = useSearch();

    const { totalResultsCount } = search.pageInfo;
    const title = generateTitleText({ searchQuery })
    return {
        title,
        totalResultsCount
    }
};

export default adaptData;
