import React from 'react';
import useSearch from '../../../../util/search/useSearch';
import View from '../../../../components/meta-data/View';
import adaptData from './adaptData';

const MetaData = ({ data }) => {
    const { searchQuery } = useSearch();

    return <View {...adaptData({ data, searchQuery })} />
};

export default MetaData;
