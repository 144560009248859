import {
    generateTitle,
    generateDescription,
    generateCanonical,
    generateOpenGraphTitle,
    generateOpenGraphDescription,
    generateOpenGraphImage,
    openGraphImageWidth,
    openGraphImageHeight,
    generateOpenGraphUrl,
    openGraphSiteName,
    generateAppleSmartBanner,
    generateGoogleSmartBanner,
    socialMediaData
} from './utils';

const adaptData = ({ data, searchQuery }) => {
    return {
        title: generateTitle(),
        meta: [
            generateDescription(),
            generateOpenGraphTitle(),
            generateOpenGraphDescription(),
            generateOpenGraphImage(),
            openGraphImageWidth,
            openGraphImageHeight,
            generateOpenGraphUrl(),
            openGraphSiteName,
            //generateAppleSmartBanner(),
            //generateGoogleSmartBanner(),
            ...socialMediaData
        ],
        link: [
            generateCanonical()
        ]
    }
}

export default adaptData;
