import {
    generateTitle,
    generateDescription,
    generateCanonical,
    generateOpenGraphTitle,
    generateOpenGraphDescription,
    generateOpenGraphImage,
    openGraphImageWidth,
    openGraphImageHeight,
    generateOpenGraphUrl,
    openGraphSiteName,
    generateAppleSmartBanner,
    generateGoogleSmartBanner,
    socialMediaData
} from './utils';
import { generateSearchResultUrl } from '../../../../@nearbites.com/query';

const adaptData = ({ data, searchQuery }) => {
    const url = generateSearchResultUrl(searchQuery);

    // TODO get the first image in the results and return it in the open graph image
    const imageUrl = '';
    
    return {
        title: generateTitle({ searchQuery }),
        meta: [
            generateDescription({ searchQuery }),
            generateOpenGraphTitle({ searchQuery }),
            generateOpenGraphDescription({ searchQuery }),
            generateOpenGraphImage(imageUrl),
            openGraphImageWidth,
            openGraphImageHeight,
            generateOpenGraphUrl(url),
            openGraphSiteName,
            //generateAppleSmartBanner(url),
            //generateGoogleSmartBanner(),
            ...socialMediaData
        ],
        link: [
            generateCanonical(url)
        ]
    }
}

export default adaptData;
