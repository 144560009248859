import React from 'react';
import styled from 'styled-components';
import useSearch from '../../../../util/search/useSearch';
import { H1, spacingHelper } from '../../../../@nitty';

const HeaderContainer = styled.div`
    display: flex;
    padding: ${spacingHelper("0 medium")};
    margin: ${spacingHelper('1rem auto 1.5rem')};

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        margin: ${spacingHelper('2rem auto 2.5rem')};
        max-width: calc(
            ${({ theme }) => theme.maxWidth} + ${spacingHelper("medium")}
        );
        padding: ${spacingHelper("0 extraLarge")};
    }
`

const Header = () => {
    const { searchQuery } = useSearch();

    return (
        <HeaderContainer>
            <H1 variant="title03">You look hungry</H1>
        </HeaderContainer>
    );
};

export default Header;
